'use strict'

angular
    .module('theme.navigation-controller', [])
	.controller('NavigationController', ["$scope", "$rootScope", "$location", "$timeout", "$global", "$modal", function ($scope, $rootScope, $location, $timeout, $global, $modal) {
		var menu = [
			{
				"label": "Main",
				"iconClasses": "fa fa-bars",
				"html": "",
				"roles": [
					"Accounts",
					"User",
					"Factory",
					"System Administrator",
					"Sales Desk",
					"Fitter",
					"Sales Person",
					"Area Manager",
					"Marketing",
					"Sales Manager",
					"Sales Person/Fitter",
					"Operations Manager",
					"Field Manager",
					"Partnership"
				],
				"children": [
					{
						"iconClasses": "fa fa-calendar",
						"label": "Appointments",
						"url": "#/appointments",
						"roles": [
							"Contracts",
							"Accounts",
							"User",
							"Factory",
							"System Administrator",
							"Sales Desk",
							"Fitter",
							"Sales Person",
							"Area Manager",
							"Marketing",
							"Sales Manager",
							"Sales Person/Fitter",
							"Operations Manager",
							"Field Manager",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-clock-o",
						"label": "Pre-Sales Functions",
						"url": "#/presales",
						"roles": [
							"Sales Desk",
							"Contracts",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						],
						"children": [
							{
								"iconClasses": "fa fa-clock-o",
								"label": "Status Action Pending",
								"url": "#/presales/status-action",
								"roles": [
									"Sales Desk",
									"Contracts",
									"Accounts",
									"Sales Manager",
									"Marketing",
									"Operations Manager",
									"System Administrator",
									"Partnership"
								]
							},
							{
								"iconClasses": "fa fa-phone",
								"label": "Unquoted Follow-Up Calls",
								"url": "#/presales/unquoted",
								"roles": [
									"System Administrator",
									"Sales Desk",
									"Operations Manager",
									"Accounts",
									"Marketing",
									"Contracts",
									"Partnership"
								]
							},
							{
								"iconClasses": "fa fa-phone",
								"label": "Quoted Follow-Up Calls",
								"url": "#/presales/quoted",
								"roles": [
									"Sales Desk",
									"Contracts",
									"Accounts",
									"Sales Manager",
									"Marketing",
									"Operations Manager",
									"System Administrator",
									"Partnership"
								]
							}
						]
					},
					{
						"iconClasses": "fa fa-envelope-o",
						"label": "Memo Inbox",
						"url": "#/inbox",
						"roles": [
							"Contracts",
							"Accounts",
							"User",
							"Factory",
							"System Administrator",
							"Sales Desk",
							"Fitter",
							"Sales Person",
							"Area Manager",
							"Marketing",
							"Sales Manager",
							"Sales Person/Fitter",
							"Operations Manager",
							"Field Manager",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-envelope-o",
						"label": "Mailing",
						"url": "#/mailing",
						"roles": [
							"Sales Desk",
							"Contracts",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-calendar",
						"label": "Mailing Log",
						"url": "#/mailing-log",
						"roles": [
							"Sales Desk",
							"Contracts",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-bars",
						"label": "Lead Providers",
						"roles": [
							"Sales Desk",
							"Contracts",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						],
						"children": [
							{
								"iconClasses": "fa fa-search",
								"label": "Search Lead Providers",
								"url": "#/lead-providers-search",
								"roles": [
									"Sales Desk",
									"Contracts",
									"Accounts",
									"Sales Manager",
									"Marketing",
									"Operations Manager",
									"System Administrator",
									"Partnership"
								]
							},
							{
								"iconClasses": "fa fa-book",
								"label": "Lead Provider Diary",
								"url": "#/lead-providers-diary",
								"roles": [
									"Sales Desk",
									"Contracts",
									"Accounts",
									"Sales Manager",
									"Marketing",
									"Operations Manager",
									"System Administrator",
									"Partnership"
								]
							},
							{
								"iconClasses": "fa fa-book",
								"label": "Lead Providers",
								"url": "#/lead-providers",
								"roles": [
									"Sales Desk",
									"Contracts",
									"Accounts",
									"Sales Manager",
									"Marketing",
									"Operations Manager",
									"System Administrator",
									"Partnership"
								]
							}
						]
					},
				]
			},
			{
				"iconClasses": "fa fa-tablet",
				"label": "iPad Dashboard",
				"url": "#/ipad-dashboard",
				"roles": [
					"Fitter",
					"Sales Person",
					"Sales Person/Fitter",
					"System Administrator"
				]
			},
			{
				"iconClasses": "fa fa-file-o",
				"label": "Contract Console",
				"url": "#/contract-console",
				"roles": [
					"Contracts",
					"System Administrator"
				]
			},
			{
				"iconClasses": "fa fa-search",
				"label": "Search Customers",
				"url": "#/customer-search",
				"roles": [
					"Contracts",
					"Accounts",
					"User",
					"Factory",
					"System Administrator",
					"Sales Desk",
					"Sales Person",
					"Area Manager",
					"Marketing",
					"Sales Manager",
					"Sales Person/Fitter",
					"Operations Manager",
					"Field Manager",
					"Partnership"
				]
			},
			{
				"iconClasses": "fa fa-search",
				"label": "Advanced Search",
				"url": "#/advanced-search",
				"roles": [
					"Contracts",
					"Accounts",
					"Factory",
					"System Administrator",
					"Sales Desk",
					"Marketing",
					"Operations Manager",
					"Partnership"
				]
			},
			{
				"iconClasses": "fa fa-search",
				"label": "Search Orders",
				"url": "#/sales-orders-search",
				"roles": [
					"Fitter",
					"Sales Person/Fitter",
					"Sales Desk",
					"Contracts",
					"Field Manager",
					"Accounts",
					"Sales Manager",
					"Marketing",
					"Operations Manager",
					"System Administrator",
					"Partnership"
				]
			},
			{
				"iconClasses": "fa fa-clock-o",
				"label": "Time Sheets",
				"url": "#/fitter-time-sheets",
				"roles": [
					"Fitter",
					"Sales Person/Fitter",
					"Marketing",
					"Partnership"
				]
			},
			{
				"label": "Reports",
				"iconClasses": "fa fa-bars",
				"html": "",
				"roles": ["System Administrator", "Sales Person", "Accounts", "Sales Manager", "Sales Person/Fitter", "Contracts", "Field Manager", "Marketing", "Operations Manager", "Partnership"],
				"children": [
					{
						"iconClasses": "fa fa-inbox",
						"label": "Sales Person's Leads",
						"url": "#/reports/salesmen-leads",
						"roles": [
							"System Administrator",
							"Sales Person",
							"Accounts",
							"Sales Manager",
							"Sales Person/Fitter",
							"Marketing",
							"Operations Manager",
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Sales Stats",
						"url": "#/reports/lead-allocation",
						"roles": [
							"System Administrator",
							"Sales Person",
							"Accounts",
							"Sales Manager",
							"Sales Person/Fitter",
							"Marketing",
							"Operations Manager",
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Enquiry Source Analysis",
						"url": "#/reports/enquiry-source-analysis",
						"roles": [
							"Marketing",
							"System Administrator",
							"Operations Manager",
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Online Enquiry by Date",
						"url": "#/reports/online-enquiry-record-count",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Sales KPI Summary",
						"url": "#/reports/kpi",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Prospects ROI Analysis",
						"url": "#/reports/mailing-ROI",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Lead Prov. Commission",
						"url": "#/reports/lead-provider-commission",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-clipboard",
						"label": "Survey Upload",
						"url": "#/reports/survey-upload",
						"roles": [
							"Contracts",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-clipboard",
						"label": "Survey Videos",
						"url": "#/reports/survey-videos",
						"roles": [
							"Contracts",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "All Work in Progress",
						"url": "#/reports/all-work-in-progress",
						"roles": [
							"Contracts",
							"Field Manager",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Missing Photos / Videos",
						"url": "#/reports/photos-videos",
						"roles": [
							"Contracts",
							"Field Manager",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Orders By Date",
						"url": "#/reports/orders-in-period",
						"roles": [
							"Contracts",
							"Field Manager",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Newsletter management",
						"url": "#/reports/newsletter-management",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-phone",
						"label": "Service Calls Outstanding",
						"url": "#/reports/service-calls-outstanding/service",
						"roles": [
							"Contracts",
							"Field Manager",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-phone",
						"label": "Remakes Outstanding",
						"url": "#/reports/service-calls-outstanding/remake",
						"roles": [
							"Contracts",
							"Field Manager",
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Monthly Sale Report",
						"url": "#/reports/monthly-sales-report",
						"roles": [
							"Accounts",
							"Sales Manager",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					},
					{
						"iconClasses": "fa fa-clock-o",
						"label": "Time Sheet Reports",
						"url": "#/reports/time-sheet-report",
						"roles": [
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator"
						]
					}
				]
			},
			{
				"iconClasses": "fa fa-tasks",
				"label": "Installer Current Work",
				"html": "",
				"roles": [
					"Fitter",
					"Sales Person/Fitter",
					"Contracts",
					"Field Manager",
					"Accounts",
					"Marketing",
					"Operations Manager",
					"System Administrator",
					"Partnership"
				],
				"children": [
					{
						"iconClasses": "fa fa-inbox",
						"label": "New Jobs",
						"url": "#/reports/installer-current-work/new-jobs",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Survey Booked",
						"url": "#/reports/installer-current-work/survey",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Surveyed",
						"url": "#/reports/installer-current-work/install",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Remakes",
						"url": "#/reports/installer-current-work/remakes",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Service Call",
						"url": "#/reports/installer-current-work/service-call",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Chargeable Service Call",
						"url": "#/reports/installer-current-work/chargeable-service-call",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Maintenence Visits",
						"url": "#/reports/installer-current-work/maintenance-visits",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-inbox",
						"label": "Customer Phone Call Request",
						"url": "#/reports/installer-current-work/customer-phone-call-request",
						"roles": [
							"Fitter",
							"Sales Person/Fitter",
							"Contracts",
							"Field Manager",
							"Accounts",
							"Marketing",
							"Operations Manager",
							"System Administrator",
							"Partnership"
						]
					}
				]
			},
			{
				"label": "Prospect Tools",
				"iconClasses": "fa fa-bars",
				"html": "",
				"roles": [
					"Sales Desk",
					"Marketing",
					"System Administrator",
					"Partnership"
				],
				"children": [
					{
						"iconClasses": "fa fa-plus",
						"label": "Add Prospect",
						"url": "#/prospect-details/0",
						"roles": [
							"Sales Desk",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-envelope-o",
						"label": "Mailer Content",
						"url": "#/mailer-content",
						"roles": [
							"Sales Desk",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-bars",
						"label": "Builder List",
						"url": "#/builder-list",
						"roles": [
							"Sales Desk",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-bars",
						"label": "Other Mailing",
						"url": "#/other-mailing",
						"roles": [
							"Sales Desk",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					},
					{
						"iconClasses": "fa fa-bars",
						"label": "Prospect Mailing Diary",
						"url": "#/prospect-mailing-diary",
						"roles": [
							"Sales Desk",
							"Marketing",
							"System Administrator",
							"Partnership"
						]
					}
				]
			},
			{
				"label": "Admin Tools",
				"iconClasses": "fa fa-bars",
				"html": "",
				"roles": [
					"System Administrator"
				],
				"children": [
					{
						"iconClasses": "fa fa-gears",
						"label": "Maintenance",
						"roles": [
							"System Administrator"
						],
						"children": [
							{
								"iconClasses": "fa fa-gbp",
								"label": "Sales Area Maintenance",
								"url": "#/maintenance-sales-area",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Parameter Maintenance",
								"url": "#/maintenance-parameters",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-users",
								"label": "Staff Maintenance",
								"url": "#/maintenance-staff",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-users",
								"label": "User Groups",
								"url": "#/maintenance-groups",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Picklist Maintenance",
								"url": "#/maintenance-picklist",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Source Detail Maintenance",
								"url": "#/maintenance-source-detail",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Product Type & Use Linking",
								"url": "#/maintenance-product-types",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Quote Options Maintenance",
								"url": "#/maintenance-quote-options-v2",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Pricing Calculator Maintenance",
								"url": "#/maintenance-pricing-calculator",
								"roles": [
									"System Administrator"
								]
							}
						]
					},
					{
						"iconClasses": "fa fa-gears",
						"label": "Utility/Setup",
						"roles": [
							"System Administrator"
						],
						"children": [
							{
								"iconClasses": "fa fa-inbox",
								"label": "Import Enquiries",
								"url": "#/import-lead",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Import Prospects",
								"url": "#/import-prospect",
								"roles": [
									"System Administrator"
								]
							},
							{
								"iconClasses": "fa fa-inbox",
								"label": "Import/Edit Templates",
								"url": "#/import-template",
								"roles": [
									"System Administrator"
								]
							}
						]
					}
				]
			}
		];

        $scope.$on('UserPopulated', () => {
            var roles = $rootScope.User.Roles.map(role => role.Name);

			$scope.menu = [];

			buildMenu(menu, roles, null);

			function buildMenu(menu, roles, childItem) {
				menu.forEach((item) => {
					if (findCommonElements3(item.roles, roles)) {
						var menuItem = {};
						if (item.children && item.children.length) {
							// create and add new item child item
							menuItem = {
								"label": item.label,
								"iconClasses": item.iconClasses,
								"html": item.html,
								"url": item.url,
								"children": []
							};
							buildMenu(item.children, roles, menuItem.children);
						} else {
							// create and add new item
							menuItem = {
								"label": item.label,
								"iconClasses": item.iconClasses,
								"url": item.url,
							};
						}
						if (childItem != null)
							childItem.push(menuItem);
						else
							$scope.menu.push(menuItem);
					}
				});
			}

			function findCommonElements3(arr1, arr2) {
				return arr1.some(item => arr2.includes(item))
			}


            var setParent = function (children, parent) {
                angular.forEach(children, function (child) {
                    child.parent = parent;
                    if (child.children !== undefined) {
                        setParent(child.children, child);
                    }
                });
            };
            $scope.searchQuery = "";
            $scope.findItemByUrl = function (children, url) {
                for (var i = 0, length = children.length; i < length; i++) {
                    if (children[i].url && children[i].url.replace('#', '') == url) return children[i];
                    if (children[i].children !== undefined) {
                        var item = $scope.findItemByUrl(children[i].children, url);
                        if (item) return item;
                    }
                }
            };

            setParent($scope.menu, null);

            $scope.openItems = [];
            $scope.selectedItems = [];
            $scope.selectedFromNavMenu = false;

            $rootScope.$on("$routeChangeSuccess", function (event, current, previous) {
                setTimeout(() => {
                    if (window.mobileAndTabletcheck()) {
                        $scope.menu.forEach((item) => {
                            item.selected = false;
                            item.open = false;
                        })
                    }
                }, 10);
            });

            $scope.select = function (item) {
                // close open nodes
                if (item.open) {
                    item.open = false;
                    return;
                }
                for (var i = $scope.openItems.length - 1; i >= 0; i--) {
                    $scope.openItems[i].open = false;
                };
                $scope.openItems = [];
                var parentRef = item;
                while (parentRef !== null) {
                    parentRef.open = true;
                    $scope.openItems.push(parentRef);
                    parentRef = parentRef.parent;
                }

                // handle leaf nodes
                if (!item.children || (item.children && item.children.length < 1)) {
                    $scope.selectedFromNavMenu = true;
                    for (var j = $scope.selectedItems.length - 1; j >= 0; j--) {
                        $scope.selectedItems[j].selected = false;
                    };
                    $scope.selectedItems = [];
                    var parentRef = item;
                    while (parentRef !== null) {
                        parentRef.selected = true;
                        $scope.selectedItems.push(parentRef);
                        parentRef = parentRef.parent;
                    }
                };
            };

            $scope.$watch(function () {
                return $location.path();
            }, function (newVal, oldVal) {
                if ($scope.selectedFromNavMenu == false) {
                    var item = $scope.findItemByUrl($scope.menu, newVal);
                    if (item)
                        $timeout(function () { $scope.select(item); });
                }
                $scope.selectedFromNavMenu = false;
            });

            // searchbar
            $scope.showSearchBar = function ($e) {
				$e.stopPropagation();
				$global.set('showSearchCollapsed', !$global.get('showSearchCollapsed'));
            }
            $scope.$on('globalStyles:changed:showSearchCollapsed', function (event, newVal) {
                $scope.style_showSearchCollapsed = newVal;
            });
			$scope.goToSearch = function () {
				$scope.HideSearchBar();
				openModal('views/modal-templates/postcode-search.html', 'PostcodeSearchController', $scope.searchQuery);
			};

			$scope.HideSearchBar = function () {
				$global.set('showSearchCollapsed', false);
			}

            function openModal(modalTemplate, Controller, modalData, config) {
                return $modal.open({
                    animation: true,
                    size: 'lg',
                    templateUrl: modalTemplate,
                    controller: Controller,
                    resolve: {
                        data: function () {
                            return modalData;
                        },
                        Id: function () {
                            return modalData;
                        },
                        config: function () {
                            return config
                        }
                    }
                });
            };

        });
    }])
